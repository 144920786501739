<template>
  <div class="oreder">
    <Spinner :is-loading="!isMapLoaded" :has-bg="false" :z-index="100" />
    <div v-if="!fillShow" class="oreder_block">
      <div class="oreder__header">
        <div v-if="orderById" class="oreder__header-block container flex">
          <h2 class="oreder__header-title">
            <span
              >000000{{
                orderById.crossdocking_id
                  ? orderById.crossdocking_id + "/" + orderById.crossdocking_number
                  : $route.params.id
              }}</span
            >
          </h2>
        </div>
      </div>
    </div>
    <div v-if="orderById" class="oreder__block container flex">
      <div class="oreder__map-block">
        <MapBlock
          :key="'map-block-parent-' + mapLoadCounter"
          class="oreder__map"
          :points="orderPointsFiltered"
          :order-by-id="orderById"
          :history-points-raw="routeHistoryPoints"
          :track-id="trackId"
          :events-toggle = eventsToggle
          @loaded="handleMapLoaded"
          @point-click="handleMapPointClick"
        />
        <InfoBlock
          v-if="!fillShow"
          class="oreder__bottom"
          :order-by-id="orderById"
          :history-points="routeHistoryPoints"
          :events-toggle = eventsToggle
          @loaded="handleHistoryLoaded"
          @sliderValueChange="handleSliderValueChange"
          @toggleRouteEvents = eventsRouteToggle
        />
      </div>
      <StatusBlock
        v-if="!fillShow"
        class="oreder__right"
        :order-by-id="orderById"
        :order-points="orderPointsFiltered"
        :clicked-point-id="clickedPointId"
        :list="orderCounterAgentsList"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Spinner from "../../../components/spinner/index.vue";
export default {
  components: {
    MapBlock: () => ({
      component: import("@/components/map/map.vue"),
    }),
    StatusBlock: () => ({
      component: import("./status"),
    }),
    InfoBlock: () => ({
      component: import("./info"),
    }),
    Spinner,
  },
  data() {
    return {
      orderCounterAgentsList: null,
      mapLoadCounter: 0,
      clickedPointId: null,
      isMapLoaded: false,
      isHistoryLoaded: false,
      historyStartDate: null,
      historyEndDate: null,
      fillShow: false,
      trackId: null,
      code: "0000000000",
      tempHistoryPointsObj: [
        {
          lat: "44.14",
          long: "44.4",
        },
        {
          lat: "42.334933",
          long: "69.564061",
        },
        {
          lat: "42.417284",
          long: "68.799416",
        },
        {
          lat: "44.4",
          long: "55.5",
        },
      ],
      tempHistoryPointsArr: [
        "Усть-Каменогорск",
        "Семей",
        "Караганда",
        "Нур-Султан",
        "Кызылорда",
        "Шымкент",
      ],
      eventsToggle: false
    };
  },
  computed: {
    ...mapState(["orderById", "routeHistoryPoints"]),
    orderPointsFiltered() {
      const filteredArr = this.orderById.points.filter((element) => {
        return element !== null && element !== undefined;
      });

      filteredArr.startDate = this.orderById.start_date;

      if(this.orderById.end_date != null){
        filteredArr.endDate = this.orderById.end_date;
      }else{
        let endDate = (new Date()).getTimezoneOffset() * 50000;
        filteredArr.endDate = (new Date(Date.now() - endDate)).toISOString().slice(0, 19).replace("T", " ");
      }
      filteredArr.orderId = this.orderById.id;

      this.$store.dispatch('getOrderTrackEvents', {
        order_id : filteredArr.orderId,
        start_date : filteredArr.startDate,
        end_date : filteredArr.endDate
      });
      return filteredArr;
    },
  },
  created() {
    try {
      this.$store.commit("incrementLoading");
      this.$api
        .get("/web/order-counter-agents", {
          params: {
            order_id: this.$route.params.id,
          },
        })
        .then((response) => {
          this.orderCounterAgentsList = [
            {
              id: null,
              name: "Выберите контрагента",
            },
            ...response.data.data,
          ];
        })
        .finally(() => {
          this.$store.commit("decrementLoading");
        });
      this.$store.dispatch("getOrderById", this.$route.params.id).then(() => {
        if (this.orderById.transport) {
          this.$store.dispatch("getRouteHistoryPoints", {
            from: (() => {
              if (Date.parse(new Date()) < Date.parse(this.orderById.start_date)) {
                const today = new Date(Date.now() - 600000);
                const date = `${today.getFullYear()}-${("0" + (today.getMonth() + 1)).slice(-2)}-${(
                  "0" + today.getDate()
                ).slice(-2)} ${today.getHours().toString().padStart(2, "0")}:${today
                  .getMinutes()
                  .toString()
                  .padStart(2, "0")}:${today.getSeconds().toString().padStart(2, "0")}`;
                this.historyStartDate = date;
                return date;
              } else {
                this.historyStartDate = this.orderById.start_date;
                return this.orderById.start_date;
              }
            })(),
            to: (() => {
              const today = new Date();
              if (!this.orderById.end_date) {
                const date = `${today.getFullYear()}-${("0" + (today.getMonth() + 1)).slice(-2)}-${(
                  "0" + today.getDate()
                ).slice(-2)} ${today.getHours().toString().padStart(2, "0")}:${today
                  .getMinutes()
                  .toString()
                  .padStart(2, "0")}:${today.getSeconds().toString().padStart(2, "0")}`;
                this.historyEndDate = date;
                return date;
              } else return this.orderById.end_date;
            })(),
            trackerId: this.orderById.transport.transport.tracker_id,
            label: this.orderById.transport.transport.label
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    handleMapLoaded(counter) {
      this.mapLoadCounter = counter;
      this.isMapLoaded = true;
    },
    handleMapPointClick(pointId) {
      this.clickedPointId = pointId;
    },
    handleSliderValueChange(sliderValue) {
      this.trackId = Number(sliderValue);
    },
    handleHistoryLoaded() {
      this.isHistoryLoaded = true;
    },
    eventsRouteToggle(){
      this.eventsToggle = !this.eventsToggle;
    }
  },
};
</script>

<style scoped>
.oreder {
  margin-bottom: 20px;
}
.oreder_block {
  padding: 18px 0 18px 0;
  background-color: #fff;
  border-top: 1px solid #f2f2f8;
}
.oreder__header-title {
  font-family: "Product Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  color: #000000;
  margin: 0;
}
.oreder__header-btn {
  position: relative;
}
.oreder__header-icon {
  margin-right: 12px;
}
.oreder__block {
  align-items: stretch;
  margin-top: 20px;
}
.order__head {
  margin-bottom: -10px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f2f2f8;
}
.oreder__map-block {
  min-height: 95vh;
  flex-grow: 1;
  /* width: 80%; */
  /*min-width: calc(100% - 310px);*/
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.oreder__map {
  /* height: 600px; */
  flex-grow: 1;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
}
.oreder__right {
  /* width: 25%; */
  width: 310px;
  margin-left: 20px;
  max-height: 725px;
  overflow: auto;
}
.oreder__right::-webkit-scrollbar {
  /* display: none; */
  visibility: hidden;
  width: 11px;
}
.oreder__right::-webkit-scrollbar-thumb {
  border-radius: 999rem;
  background-color: #0002;
}
.oreder__right::-webkit-scrollbar-thumb:hover {
  background-color: #aaa9;
}
.oreder__bottom {
  margin-top: 20px;
  /* width: 100%; */
  /* height: 90px; */
  background: #ffffff;
  border-radius: 20px;
}
.map-info {
  position: absolute;
  top: 30%;
  left: 45%;
}
</style>
